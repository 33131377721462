import {
  SensorLineChart,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import {
  useGraphTouchEvents,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import {
  ChartConfig,
  ChartData,
  Comment,
  CommentApiFilter,
  FlatNotification,
  Incident,
  Locale,
  NewCommentReferenceDot,
  Notification,
  OperationalSample,
  Sensor,
  SensorType,
  Sentinel,
  ShowNewCommentPopupPayload,
  ThresholdLine,
  UsersById,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import {
  convertSensorTypesToSentinelTypes,
  determineNewCommentPopupInfo,
  determineResponsiveXTicksAfterZoomIn,
  generateResponsiveXTicks,
  getChartConfig,
  processNotificationsByTypes,
  processSamplesData,
  processThresholdLines,
} from '@energybox/react-ui-library/dist/utils';
import { determineDateRangeAfterZoomIn } from '@energybox/react-ui-library/dist/utils/chart';
import { subHours, toDate as toDateFn } from 'date-fns';
import equals from 'ramda/src/equals';
import pathOr from 'ramda/src/pathOr';
import uniq from 'ramda/src/uniq';

import React from 'react';
import { connect } from 'react-redux';
import { getComments, showNewCommentPopup } from '../../actions/comments';
import { getFlatNotificationsBySensorId } from '../../actions/notifications';
import { getOperationalSamplesBySensorId } from '../../actions/samples';
import { getSensorById } from '../../actions/sensors';
import { getSentinelById } from '../../actions/sentinels';
import { getUsers } from '../../actions/users';
import CommentModeButton from '../../components/CommentModeButton';
import CommentsFeatureFlag, {
  canAccessCommentsFeature,
} from '../../components/CommentsFeatureFlag';
import NewCommentPopup from '../../components/NewCommentPopup';
import { withViewportType } from '../../components/withViewportType';
import useCurrentUser from '../../hooks/useCurrentUser';
import { ApplicationState } from '../../reducers';
import { App } from '../../reducers/app';
import { filterAndProcessComments } from '../../utils/comments';
import {
  determineFetchTimeRangeForNotificationChart,
  getIdsForNotificationChart,
} from '../../utils/notifications';
import styles from './NotificationOperationalSensorChartContainer.module.css';
import { DateTime } from 'luxon';

interface OwnProps {
  sensorId: number;
  sensorType: SensorType;
  locale: Locale;
  featureNotification?: Notification;
  featureIncident?: Incident;
  viewportType: ViewportTypes;
  ianaTimeZoneCode?: string;
}

interface Props extends OwnProps {
  app: App;
  isMobile: boolean;
  getSensor: () => void;
  getSentinels: () => void;
  getFlatNotifications: (
    currentFromDate: number,
    currentToDate: number
  ) => void;
  getOperationalSamples: (
    currentFromDate: number,
    currentToDate: number
  ) => void;
  getUsers: (userIds: number[]) => void;
  getComments: (queryParams: CommentApiFilter) => void;
  usersById: UsersById;
  sensor?: Sensor;
  sentinels: Sentinel[];
  flatNotifications: FlatNotification[];
  operationalSamples: OperationalSample[];
  isLoading: boolean;
  isNewCommentPopupOpen?: boolean;
  showNewCommentPopup: (payload: ShowNewCommentPopupPayload) => void;
  comments?: Comment[];
}

type State = {
  // These two preserve the date filter when user zooms into section
  initialFromDate: number;
  initialToDate: number;
  // These two set the zoom level
  currentFromDate: number;
  currentToDate: number;
  ticks: number[];
  isCustomRange?: boolean;
  refAreaStart: string;
  refAreaEnd: string;
  data: ChartData[];
  chartConfig?: { [key: string]: ChartConfig };
  thresholdLines: ThresholdLine[];
  isCommentModeActive: boolean;
  newCommentReferenceDot?: NewCommentReferenceDot;
  newCommentPopupXCoordinate: number;
};

class NotificationOperationalSensorChartContainer extends React.Component<
  Props,
  State
> {
  private chartContainerRef = React.createRef<HTMLDivElement>();
  constructor(props: Props) {
    super(props);
    const {
      app: { currentUser },
      featureNotification,
      featureIncident,
      isMobile,
    } = props;
    let fromDate: number, toDate: number;

    const dateFilter = determineFetchTimeRangeForNotificationChart(
      featureNotification,
      featureIncident
    );
    fromDate = dateFilter.fromDate;
    toDate = dateFilter.toDate;

    this.state = {
      initialFromDate: fromDate,
      initialToDate: toDate,
      currentFromDate: fromDate,
      currentToDate: toDate,
      ticks: generateResponsiveXTicks({ fromDate, toDate, isMobile }),
      chartConfig: currentUser
        ? getChartConfig([props.sensorType], currentUser)
        : undefined,
      refAreaStart: '',
      refAreaEnd: '',
      data: [],
      thresholdLines: [],
      isCommentModeActive: false,
      newCommentReferenceDot: undefined,
      newCommentPopupXCoordinate: -1,
    };
  }

  componentDidMount() {
    const {
      app: { currentUser },
      getSensor,
      getSentinels,
      getFlatNotifications,
      getOperationalSamples,
      getComments,
      sentinels,
      locale,
      sensorType,
      sensor,
    } = this.props;
    const { currentFromDate, currentToDate } = this.state;

    if (!sensor) getSensor();
    getOperationalSamples(currentFromDate, currentToDate);
    getFlatNotifications(currentFromDate, currentToDate);
    getComments({
      from: new Date(currentFromDate).toISOString(),
      to: new Date(currentToDate).toISOString(),
    });

    if (currentUser && sentinels.length) {
      this.setState({
        thresholdLines: processThresholdLines(
          sentinels,
          currentUser,
          convertSensorTypesToSentinelTypes([sensorType])
        ),
      });
    } else {
      getSentinels();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      app: { currentUser },
      isMobile,
      sentinels,
      getUsers,
      getComments,
      getFlatNotifications,
      getOperationalSamples,
      operationalSamples,
      sensorType,
      locale,
      comments,
    } = this.props;
    const { currentFromDate, currentToDate, chartConfig } = this.state;

    if (currentUser && !chartConfig) {
      this.setState({
        chartConfig: getChartConfig([sensorType], currentUser),
      });
    }
    const didTimeChange =
      !equals(prevState.currentFromDate, currentFromDate) ||
      !equals(prevState.currentToDate, currentToDate);
    const didCommentsChange = !equals(prevProps.comments, comments);
    const didSentinelsChange = !equals(prevProps.sentinels, sentinels);
    const didOperationalSamplesChange = !equals(
      prevProps.operationalSamples,
      operationalSamples
    );
    const didUserChange = !equals(prevProps.app.currentUser, currentUser);
    const didViewportChange = !equals(prevProps.isMobile, isMobile);

    if (didCommentsChange && comments && comments.length > 0) {
      const userIds = comments.map((c) => c.userId);
      getUsers(uniq(userIds));
    }

    if (didTimeChange) {
      this.setState({ data: [] });
      getOperationalSamples(currentFromDate, currentToDate);
      getFlatNotifications(currentFromDate, currentToDate);
      getComments({
        from: new Date(currentFromDate).toISOString(),
        to: new Date(currentToDate).toISOString(),
      });
    }

    if (currentUser && didSentinelsChange) {
      this.setState({
        thresholdLines: processThresholdLines(
          sentinels,
          currentUser,
          convertSensorTypesToSentinelTypes([sensorType])
        ),
      });
    }

    if ((didUserChange || didOperationalSamplesChange) && currentUser) {
      this.setState({
        chartConfig: getChartConfig([sensorType], currentUser),
        data: processSamplesData(operationalSamples, currentUser),
      });
    }

    if (didViewportChange) {
      this.setState({
        ticks: generateResponsiveXTicks({
          fromDate: currentFromDate,
          toDate: currentToDate,
          isMobile,
        }),
      });
    }
  }

  updateRefAreaStart = (newRefAreaStart: string) => {
    this.setState({ refAreaStart: newRefAreaStart });
  };

  updateRefAreaEnd = (newRefAreaEnd: string) => {
    this.setState({ refAreaEnd: newRefAreaEnd });
  };

  zoomIn = () => {
    const { refAreaStart, refAreaEnd } = this.state;
    const { isMobile } = this.props;
    const refAreaStartNumber = parseInt(refAreaStart);
    const refAreaEndNumber = parseInt(refAreaEnd);

    if (refAreaStart === refAreaEnd || refAreaEnd === '') {
      this.setState(() => ({
        refAreaStart: '',
        refAreaEnd: '',
      }));
      return;
    }

    const { fromDate: newFromDate, toDate: newToDate } =
      determineDateRangeAfterZoomIn(refAreaStartNumber, refAreaEndNumber);

    const newTicks = determineResponsiveXTicksAfterZoomIn({
      fromDate: refAreaStartNumber,
      toDate: refAreaEndNumber,
      isMobile,
    });

    this.setState({
      currentFromDate: newFromDate,
      currentToDate: newToDate,
      ticks: newTicks,
      refAreaStart: '',
      refAreaEnd: '',
    });
  };

  zoomOut = () => {
    const { initialFromDate, initialToDate } = this.state;
    const { isMobile } = this.props;

    this.setState({
      currentFromDate: initialFromDate,
      currentToDate: initialToDate,
      ticks: generateResponsiveXTicks({
        fromDate: initialFromDate,
        toDate: initialToDate,
        isMobile,
      }),
    });
  };

  onAddCommentButtonClick = () => {
    this.setState({
      isCommentModeActive: !this.state.isCommentModeActive,
    });
  };

  onClickDuringCommentMode = (e) => {
    const {
      app: { currentUser },
      sensorId,
      sensorType,
      isNewCommentPopupOpen,
      showNewCommentPopup,
    } = this.props;
    const { isCommentModeActive } = this.state;
    if (currentUser && isCommentModeActive && e && !isNewCommentPopupOpen) {
      const newCommentPopupInfo = determineNewCommentPopupInfo(
        e,
        sensorType.toLowerCase(),
        sensorId,
        ValueType[sensorType],
        this.chartContainerRef,
        currentUser
      );
      if (newCommentPopupInfo) {
        const {
          newCommentReferenceDot,
          newCommentPopupXCoordinate,
          newCommentPopupPayload,
        } = newCommentPopupInfo;

        this.setState({
          newCommentPopupXCoordinate: newCommentPopupXCoordinate,
          newCommentReferenceDot: newCommentReferenceDot,
        });
        showNewCommentPopup(newCommentPopupPayload);
      }
    }
  };

  onNewCommentPopupUnmount = () => {
    this.setState({
      newCommentPopupXCoordinate: -1,
      newCommentReferenceDot: undefined,
    });
  };

  render() {
    const {
      initialFromDate,
      initialToDate,
      currentFromDate,
      currentToDate,
      ticks,
      refAreaStart,
      refAreaEnd,
      data,
      chartConfig,
      thresholdLines,
      isCommentModeActive,
      newCommentReferenceDot,
      newCommentPopupXCoordinate,
    } = this.state;
    const {
      ianaTimeZoneCode,
      app,
      sensor,
      sensorId,
      usersById,
      sensorType,
      locale,
      flatNotifications,
      isLoading,
      isNewCommentPopupOpen,
      comments,
      isMobile,
    } = this.props;

    if (!app.currentUser) return null;
    const isZoomedIn =
      initialFromDate !== currentFromDate || initialToDate !== currentToDate;
    const filteredAndProcessedComments = filterAndProcessComments(
      comments,
      [sensorId],
      ValueType[sensorType],
      app.currentUser
    );

    return (
      <div className={styles.root}>
        <header className={styles.header}>
          <div>
            <h5 className={styles.title}>Sensor Activity</h5>
          </div>
          {!isMobile && (
            <div className={styles.rightAlign}>
              {/* TODO COMMENTS: delete FeatureFlag all orgs can use commentsFeature */}
              <CommentsFeatureFlag>
                <CommentModeButton
                  className={styles.commentButtonContainer}
                  onClick={this.onAddCommentButtonClick}
                  disabled={isLoading}
                  isActive={isCommentModeActive}
                />
              </CommentsFeatureFlag>

              <div className={styles.zoomResetContainer}>
                <ResetButton
                  customText="Reset Zoom"
                  displayInfoTooltip
                  onClick={this.zoomOut}
                  disabled={!isZoomedIn || isCommentModeActive}
                />
              </div>
            </div>
          )}
        </header>

        <div className={styles.chartContainer} ref={this.chartContainerRef}>
          <SensorLineChartWrapper
            {...{
              isMobile,
              ianaTimeZoneCode,
              isLoading,
              sensor,
              sensorType,
              flatNotifications,
              locale,
              chartConfig,
              thresholdLines,
              data,
              currentFromDate,
              currentToDate,
              ticks,
              refAreaStart,
              refAreaEnd,
              isCommentModeActive,
              filteredAndProcessedComments,
              newCommentReferenceDot,
              isNewCommentPopupOpen,
              hideCommentsCheckbox: !canAccessCommentsFeature(app),
              onClickDuringCommentMode: this.onClickDuringCommentMode,
              updateRefAreaStart: this.updateRefAreaStart,
              updateRefAreaEnd: this.updateRefAreaEnd,
              zoomIn: this.zoomIn,
            }}
          />

          {isNewCommentPopupOpen && newCommentPopupXCoordinate && sensor && (
            <div
              style={{
                position: 'absolute',
                top: -50,
                left: newCommentPopupXCoordinate,
              }}
            >
              <NewCommentPopup
                ianaTimeZoneCode={ianaTimeZoneCode}
                resourceId={sensorId}
                resourceTitle={sensor.title}
                resourceType={ValueType[sensorType]}
                onUnmount={this.onNewCommentPopupUnmount}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  {
    app,
    users,
    sensors,
    comments,
    sentinels,
    operationalSamples,
    notifications,
  }: ApplicationState,
  {
    sensorId,
    featureNotification,
    featureIncident,
    sensorType,
    viewportType,
  }: OwnProps
) => {
  const { sentinelId, notificationId } = getIdsForNotificationChart(
    featureNotification,
    featureIncident
  );

  return {
    app: app,
    isMobile: viewportType === ViewportTypes.MOBILE,
    usersById: users.usersById,
    comments: pathOr(
      undefined,
      [sensorId, 'comments'],
      comments.commentsByResourceId
    ),
    sensor: sensors.sensorsById[sensorId],
    sentinels: sentinels.byId[sentinelId] || [],
    flatNotifications:
      notifications.flatNotificationsByFeatureNotificationId[notificationId] ||
      [],
    operationalSamples:
      operationalSamples.operationalSamplesByFeatureNotificationId[
        notificationId
      ] || [],
    isLoading:
      operationalSamples.isLoadingByResourceId[notificationId] ||
      notifications.isLoadingByResourceId[notificationId] ||
      sentinels.isLoadingByResourceId[sentinelId] ||
      pathOr(false, [sensorId, 'isLoading'], comments.commentsByResourceId),
    isNewCommentPopupOpen:
      comments.isNewCommentPopupOpenByResourceId_ResourceType[
        `${sensorId}_${sensorType}`
      ],
  };
};

const mapDispatchToProps = (
  dispatch,
  { sensorId, featureNotification, featureIncident, sensorType }: OwnProps
) => {
  const { sentinelId, notificationId } = getIdsForNotificationChart(
    featureNotification,
    featureIncident
  );

  return {
    getSensor: () => dispatch(getSensorById(sensorId)),
    getOperationalSamples: (currentFromDate: number, currentToDate: number) => {
      dispatch(
        getOperationalSamplesBySensorId(
          sensorId,
          {
            sensorId: String(sensorId),
            from: DateTime.fromMillis(currentFromDate).toUTC().toISO(),
            to: DateTime.fromMillis(currentToDate).toUTC().toISO(),
          },
          notificationId
        )
      );
    },
    getFlatNotifications: (currentFromDate: number, currentToDate: number) =>
      dispatch(
        getFlatNotificationsBySensorId(
          sensorId,
          {
            sensorIds: [sensorId],
            from: toDateFn(currentFromDate),
            to: toDateFn(currentToDate),
          },
          notificationId
        )
      ),
    getSentinels: () => dispatch(getSentinelById(sentinelId)),
    showNewCommentPopup: (payload: ShowNewCommentPopupPayload) =>
      dispatch(showNewCommentPopup(`${sensorId}_${sensorType}`, payload)),
    getUsers: (userIds: number[]) => dispatch(getUsers(userIds)),
    getComments: (queryParams: CommentApiFilter) =>
      dispatch(getComments(sensorId, queryParams)),
  };
};

export default withViewportType(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NotificationOperationalSensorChartContainer)
);

const SensorLineChartWrapper = ({
  isMobile,
  ianaTimeZoneCode,
  isLoading,
  sensor,
  sensorType,
  flatNotifications,
  locale,
  chartConfig,
  thresholdLines,
  data,
  currentFromDate,
  currentToDate,
  ticks,
  refAreaStart,
  refAreaEnd,
  updateRefAreaStart,
  updateRefAreaEnd,
  zoomIn,
  isCommentModeActive,
  filteredAndProcessedComments,
  newCommentReferenceDot,
  onClickDuringCommentMode,
  isNewCommentPopupOpen,
  hideCommentsCheckbox,
}: {
  isMobile;
  ianaTimeZoneCode?: string;
  isLoading;
  sensor;
  sensorType;
  flatNotifications;
  locale;
  chartConfig;
  thresholdLines;
  data;
  currentFromDate;
  currentToDate;
  ticks;
  refAreaStart;
  refAreaEnd;
  updateRefAreaStart;
  updateRefAreaEnd;
  zoomIn;
  isCommentModeActive;
  filteredAndProcessedComments;
  newCommentReferenceDot;
  onClickDuringCommentMode;
  isNewCommentPopupOpen;
  hideCommentsCheckbox;
}) => {
  const currentUser = useCurrentUser();
  const graphTouchEvents = useGraphTouchEvents(
    true,
    updateRefAreaStart,
    updateRefAreaEnd,
    zoomIn
  );
  if (!currentUser) return null;

  return (
    <SensorLineChart
      ianaTimeZoneCode={ianaTimeZoneCode}
      legendPrimaryLabel={sensor?.title}
      sensorType={[sensorType]}
      locale={locale}
      fromDate={currentFromDate}
      toDate={currentToDate}
      ticks={ticks}
      refAreaStart={refAreaStart}
      refAreaEnd={refAreaEnd}
      startZoom={graphTouchEvents.startZoom}
      onTouchStart={graphTouchEvents.onTouchStart}
      onTouchMove={graphTouchEvents.onTouchMove}
      onTouchEnd={graphTouchEvents.onTouchEnd}
      notifications={processNotificationsByTypes(
        flatNotifications,
        currentUser
      )}
      isLoading={isLoading}
      isMobile={isMobile}
      chartConfig={chartConfig}
      thresholdLines={thresholdLines}
      data={data}
      onClickDuringCommentMode={onClickDuringCommentMode}
      newCommentReferenceDot={newCommentReferenceDot}
      isCommentModeActive={isCommentModeActive}
      isNewCommentPopupOpen={isNewCommentPopupOpen}
      comments={filteredAndProcessedComments}
      //TODO COMMENTS: delete once all orgs can use commentsFeature
      hideCommentsCheckbox={hideCommentsCheckbox}
      hideControlDetailsCheckbox
    />
  );
};
